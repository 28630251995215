import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    value: _ctx.items,
    stripedRows: "",
    rowHover: true,
    responsiveLayout: "stack",
    breakpoint: "960px",
    scrollHeight: "80vh",
    onRowClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickRow($event))),
    onSort: _cache[1] || (_cache[1] = ($event: any) => (_ctx.sortColumn($event)))
  }, {
    empty: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.emptyMessage), 1)
    ]),
    loading: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.loadingMessage), 1)
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
        return (_openBlock(), _createElementBlock("div", null, [
          _createVNode(_component_Column, {
            field: column.field,
            header: column.header,
            style: {"min-width":"14rem"},
            sortable: true
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(data.data[`${column.field}`]), 1)
            ]),
            _: 2
          }, 1032, ["field", "header"])
        ]))
      }), 256))
    ]),
    _: 1
  }, 8, ["value"]))
}