
import { defineComponent } from "vue"

export default defineComponent({
  name: "CustomDataTable",
  emits: ["clickRow", "sortColumn"],
  props: {
    items: {
      type: Array,
      required: true,
    },
    emptyMessage: {
      type: String,
      required: true,
    },
    loadingMessage: {
      type: String,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    //doesnt work
    function clickRow(e: any) {
      emit("clickRow", e)
    }

    function sortColumn(e: any) {
      emit("sortColumn", e)
    }

    return {
      clickRow,
      sortColumn,
    }
  },
})
