
import { ref, onMounted, onUnmounted, watch, nextTick, computed, defineComponent } from "vue"
// import {useRouter} from "vue-router";
import UserService from "@/services/UserService"
import { FilterMatchMode, FilterOperator } from "primevue/api"
import ResultSet from "@/models/ResultSet.js"
import UserForm from "@/components/users/UserForm.vue"
import { RouterLink, useRouter } from "vue-router"
import CustomDataTable from "../data/CustomDataTable.vue"
import Button from "primevue/button"
import Column from "primevue/column"
import DataTable from "primevue/datatable"
import Dialog from "primevue/dialog"
import Dropdown from "primevue/dropdown"
import InputText from "primevue/inputtext"
import LinkLabel from "../navigation/LinkLabel.vue"
import PrimaryButton from "../buttons/PrimaryButton.vue"

export default defineComponent({
  components: { UserForm, CustomDataTable, LinkLabel, PrimaryButton },
  setup() {
    let part = 0
    const loadedAllData = ref(false)
    const loadingPart = ref(false)
    const scrollComponent = ref()
    const items = ref([])
    const selectedItems = ref([])
    const lastError = ref(null)
    const userService = ref(new UserService())
    const loading = ref(true)
    const timeout = ref(null)

    const filter = ref("")
    const sort = ref("")
    const displayNewUser = ref(false)

    const router = useRouter()

    const windowWidth = ref(window.innerWidth)

    const showSortOptions = computed(() => windowWidth.value < 960)
    const scrollable = computed(() => windowWidth.value > 960)

    const sortOptions = ref([
      { label: "Username ↓", value: "username,asc" },
      { label: "Username ↑", value: "username,desc" },
      { label: "Last Name ↓", value: "lastName,asc" },
      { label: "Last Name ↑", value: "lastName,desc" },
      { label: "First Name ↓", value: "firstName,asc" },
      { label: "First Name ↑", value: "firstName,desc" },
      { label: "Email ↓", value: "email,asc" },
      { label: "Email ↑", value: "email,desc" },
    ])

    loadPart(0, "", filter.value)

    onMounted(() => {
      loading.value = false
      scrollComponent.value = document.querySelector(".p-datatable-wrapper")
      if (scrollComponent.value) {
        scrollComponent.value.addEventListener("scroll", checkVisible, { passive: true })
      }

      window.addEventListener("resize", checkVisible, { passive: true })
    })

    onUnmounted(() => {
      if (scrollComponent.value) {
        scrollComponent.value.removeEventListener("scroll", checkVisible)
      }

      window.removeEventListener("resize", checkVisible)
    })

    function search() {
      clearTimeout(timeout.value)
      timeout.value = setTimeout(() => {
        reload()
      }, 300)
    }

    watch(
      () => sort.value,
      () => {
        reload()
      }
    )

    function loadPart(part: Number, sort: any, filter: string) {
      loadingPart.value = true
      userService.value.getUsers(part, sort, filter).then((data) => {
        onPartLoaded(part, sort.value, data)
      })
    }

    function reload() {
      loadingPart.value = true
      part = 0
      selectedItems.value = null
      loadedAllData.value = false
      items.value = []
      loadPart(part, sort.value, filter.value)
    }

    function checkVisible() {
      windowWidth.value = window.innerWidth
      if (loadedAllData.value) return
      if (loadingPart.value) return

      if (
        Math.ceil(scrollComponent.value.scrollTop * 1.3) >=
        scrollComponent.value.scrollHeight - scrollComponent.value.clientHeight
      ) {
        loadPart(part, sort.value, filter.value)
      }
    }

    function onPartLoaded(loadedPart: Number, sort: any, result: ResultSet<any>) {
      loadingPart.value = false
      loadedAllData.value = !result.moreToCome

      if (result.rows.length == 0) {
        return
      }

      part++
      result.rows.map(function (element) {
        let item = { selected: false, data: element }
        items.value.push(item)
      })

      nextTick().then(() => {
        checkVisible()
      })
    }

    function onPartFailed(error: any) {
      loadingPart.value = false
      lastError.value = error
    }

    const clearFilter = () => {
      filter.value = ""
    }

    const closeNewUserDisplay = () => {
      displayNewUser.value = false
    }

    const openNewUserDisplay = () => {
      displayNewUser.value = true
    }

    const openUser = (event: any) => {
      router.push({ name: "userProfile", params: { user_id: items.value[event.index].data.id } })
    }

    function createNewUser(user: any) {
      userService.value.addUser(user).then((result) => {
        router.push({ name: "userProfile", params: { user_id: result } })
      })
    }

    const sortColumn = (event: any) => {
      if (event.value) {
        sort.value = event.value
      } else {
        sort.value = event.sortField + "," + (event.sortOrder == 1 ? "desc" : "asc")
      }
    }

    return {
      loading,
      loadedAllData,
      loadingPart,
      scrollComponent,
      items,
      selectedItems,
      lastError,
      loadPart,
      reload,
      onPartLoaded,
      onPartFailed,
      filter,
      clearFilter,
      displayNewUser,
      openNewUserDisplay,
      closeNewUserDisplay,
      createNewUser,
      openUser,
      sortColumn,
      showSortOptions,
      sort,
      sortOptions,
      search,
      scrollable,
    }
  },
})
